import React from "react"
import { withStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import * as actions from "../state/actions/keymans"
import { CORP_PER_PAGE } from "../constants/constants"
import ListLayout from "../components/common/list-layout"
import CorporationItem from "../components/common/corporation-item"
import { Location } from "@reach/router"
import { getQueryVariable } from "../utils/str"

const mapStateToProps = (state, ownProps) => {
  return {
    corporations: state.corporations.corporations.toIndexedSeq().toArray(),
    pageInfo: state.corporations.pageInfo_corp,
    isLoading: state.corporations.isLoadingCorps,
    keymansTotal: state.global.keymansTotal,
    corporationsTotal: state.global.corporationsTotal,
    updateKeymans: state.global.updateKeymans.toIndexedSeq().toArray(),
    isLoadingUpdateKeymans: state.global.isLoadingUpdateKeymans,
    keymanCountYstd: state.global.keymanCountYstd,
    isInitialized: state.corporations.isInitialized,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCorporations: (data, overwrite) =>
      dispatch(actions.getCorporations(data, overwrite)),
    getKeymansYesterday: () => dispatch(actions.getKeymansYesterday()),
    getKeymansTotal: () => dispatch(actions.getKeymansTotal()),
  }
}

const styles = theme => ({})

class Corporations extends React.Component {
  componentDidMount() {
    this.props.getKeymansTotal()
    this.props.getKeymansYesterday()
  }

  getQuery = (props, query = {}) => {
    return {
      paging: 1,
      per_page: CORP_PER_PAGE,
      page: 1,
      ...getQueryVariable(props.location.search, ["page"]),
      ...query,
    }
  }

  render() {
    const {
      corporations,
      isLoading,
      pageInfo,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      updateKeymans,
      isLoadingUpdateKeymans,
    } = this.props

    return (
      <ListLayout
        name="全国の会社"
        title="全国の会社一覧"
        ItemComponent={CorporationItem}
        items={corporations}
        isLoading={isLoading}
        perPage={CORP_PER_PAGE}
        pageInfo={pageInfo}
        getItems={(data, overwrite) =>
          this.props.getCorporations(this.getQuery(this.props, data), overwrite)
        }
        updateKeymans={updateKeymans}
        isLoadingUpdateKeymans={isLoadingUpdateKeymans}
        keymanCountYstd={keymanCountYstd}
        keymansTotal={keymansTotal}
        corporationsTotal={corporationsTotal}
        path="corporations"
        isInitialized={this.props.isInitialized}
        displayTotal={corporationsTotal}
      />
    )
  }
}

export default withStyles(styles)(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => (
    <Location>
      {locationProps => <Corporations {...locationProps} {...props} />}
    </Location>
  ))
)
