import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"

import qs from "qs"
import Pagination from "./pagination"
import Banner from "./banner"
import Card from "./card"
import Layout from "./layout"
import SEO from "./seo"
import BreadcrumbList from "./breadcrumb-list"
import ServiceDescription from "../atoms/service-description"
import LinkToLpText from "../atoms/link-to-lp-text"
import LinkToUpdate from "./link-to-update"
import { Location } from "@reach/router"

const styles = theme => ({
})

class ListLayout extends React.Component {
  componentDidMount() {
    this.props.getItems({}, true)
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isLoading && prevProps.isLoading) {
      if (!!window) window.scrollTo(0, 0)
    }

    if (this.props.location.href !== prevProps.location.href) {
      this.props.getItems()
    }
  }

  render() {
    const {
      title,
      name,
      isLoading,
      items,
      perPage,
      ItemComponent,
      pageInfo,
      getItems,
      keymanCountYstd,
      keymansTotal,
      corporationsTotal,
      reverseLayout,
      path,
      params,
      isInitialized,
      children,
      corporationName,
    } = this.props

    let isDepartmentCustomPage = false
    let isCorporationCustomPage = false
    let isIndustriesPage = false
    let lsic_l = ''
    let corpId = ''
    const jsic_l_val = path ? path.match("industries/(.*)/departments") : ''
    const corp_departments_val = path ? path.match("corporations/(.*)/departments") : ''
    const corp_positions_val = path ? path.match("corporations/(.*)/positions") : ''
    const industries_page = path && path.match("industries") && !path.match("departments") ? true : false
    if (jsic_l_val) {
      isDepartmentCustomPage = true
      lsic_l = decodeURI(jsic_l_val[1])
    } else if (corp_departments_val || corp_positions_val) {
      isCorporationCustomPage = true
      corpId =  corp_departments_val ? decodeURI(corp_departments_val[1]) : corp_positions_val ? decodeURI(corp_positions_val[1]) : ''
    } else if (industries_page) {
      isIndustriesPage = true
    }

    let listItems =
      !isInitialized || (isLoading && items && items.length === 0) ||
      (isDepartmentCustomPage && isLoading) ||
      (isCorporationCustomPage && isLoading) ||
      (isIndustriesPage && isLoading)
        ? Array.from(Array(perPage))
        : items

    return (
      <Layout hasFooterPopup={true}>
        {path && path.match(/[a-z]/) && (
          <SEO
            title={title}
            description={`${
              title ? title + "です。" : ""
            }大企業決裁者との商談獲得のためのレター送付ツール「キーマンレター」なら48万人以上の決裁者をリスト化してレターでコンタクトが取れます。`}
            canonical={`https://keyman-db.smart-letter.com/${path}`}
          />
        )}
        <div className="container">
          <div className="list-container list-margin">
            <main
              className={`left${reverseLayout ? " left-reverse-order" : ""}`}
            >
              <BreadcrumbList
                list={
                  isDepartmentCustomPage ?
                  [ { path: "/", text: "トップ" },
                    { path: "/industries/" + lsic_l, text: lsic_l }] :
                  isCorporationCustomPage ?
                  [ { path: "/", text: "トップ" },
                    { path: "/corporations/" + corpId, text: corporationName }] :
                  [ { path: "/", text: "トップ" } ]
                }
                current={
                  path === "search" ?
                  { path: `/${path}?${qs.stringify({ ...params }, { encode: false, arrayFormat: "indices" })}`, text: name } :
                  { path: `/${path}`, text: name + "一覧" }
                }
              />
              <h2 className="title">{name}{path !== "search" ? "一覧" : ""}</h2>
              <ServiceDescription
                children={<LinkToLpText/>}
              />
              {children}
              {listItems.length === 0 ? (
                <div>{name}がありません。</div>
              ) : (
                <div className="list">
                  {listItems.map((item, i) => (
                    <ItemComponent
                      item={item}
                      key={i}
                      showBorderBottom={i !== listItems.length - 1}
                    />
                  ))}
                </div>
              )}

              {listItems.length > 0 && pageInfo && !isLoading ? (
                <Pagination
                  currentPage={pageInfo.current_page}
                  lastPage={pageInfo.last_page}
                  perPage={perPage}
                  onChange={getItems}
                  path={path}
                  params={params}
                  total={pageInfo.total}
                  displayTotal={this.props.displayTotal}
                />
              ) : (
                <div style={{ height: "213px" }}></div>
              )}
            </main>
            <div
              className={`right${reverseLayout ? " right-reverse-order" : ""}`}
            >
              <div className="banner">
                <Banner short={true} />
              </div>

              <h2 className="title">昨日更新された決裁者の人数</h2>
              <LinkToUpdate
                keymanCountYstd={keymanCountYstd}
                updateKeymans={this.props.updateKeymans}
                isLoadingUpdateKeymans={this.props.isLoadingUpdateKeymans}
                isShowUpdateInfo={this.props.isShowUpdateInfo}
              />
              <Card
                keymanCountYstd={keymanCountYstd}
                keymansTotal={keymansTotal}
                corporationsTotal={corporationsTotal}
                style={{
                  marginBottom: "35px",
                }}
              />
            </div>
          </div>
          <Banner />
        </div>
      </Layout>
    )
  }
}

ListLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  keyman: PropTypes.object,
  short: PropTypes.bool,
  small: PropTypes.bool,
  reverseLayout: PropTypes.bool,
}

ListLayout.defaultProps = {
  reverseLayout: true,
  isInitialized: true,
  isShowUpdateInfo: true,
  displayTotal: 0,
}

export default withStyles(styles)(props => (
  <Location>
    {locationProps => <ListLayout {...locationProps} {...props} />}
  </Location>
))
